body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

table.selection, td.selection, th.selection {
  table-layout: fixed;
}

td.selection, th.selection {
  border: 1px solid #e0e0e0;
  user-select: none;
}

table.selection {
  border-style: hidden; /* hide standard table (collapsed) border */
  /*box-shadow: 0 0 0 1px black; /* this draws the table border
  border-radius: 4px;*/
  border-bottom: 1px;
  border-collapse: collapse;
  flex-grow: 1;
}

td.selection {
  position: relative;
  text-align: center
}

th.selection {
  position: sticky;
  top: 0;
  overflow: hidden;
  outline: 1px solid black;
  background: white;
  z-index: 2;
}

p.rowText {
  text-align: center
}

.resizer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10px;
  height: 100%;
  position: absolute;
  right: 5px;
  top: 0;
  transform: translateX(50%);
  z-index: 1;
  touch-action:none;
}

.rowHeader {
  border: 1px solid black;
}

.centerText {
  text-align: center;
  vertical-align: middle;
}

#root {
  height: 100vh;
}

.displayTd {
  border: 2px solid green;
}

@media only screen and (min-width: 600px) {
  .menuItem {
    width: 33vw;
    max-width: 325px;
  }
}

